// *****************************************************
// Reskin
// *****************************************************
.top-agro {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.64)), url('/img/agroecology.jpg');
    padding-bottom: 100px;
    height: 600px;
}

.top-frn {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.409)), url('/img/frn-splash.jpg');
    padding-bottom: 100px;
    height: 600px;
    background-position: center;
}

.frn-cards .card {
    width: 285px;
    min-width: 285px;
    min-height: 185px;
    flex: initial;
    background-color: #d32329;
    color: #ffffff !important;
    border-radius: 15px !important;
    padding: 1rem;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
}

.frn-cards .card:hover {
    background-color: #851014;
}

.frn-cards .card-title {
    color: #ffffff !important;
}

.frn-cards .card-text {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: normal;
}

.frn .navbar-nav {
    margin-left: auto !important;
    margin-right: auto !important;
}

.frn .navbar {
    border-bottom: none;
}

.frn .filterbox {
    margin-bottom: 0.5rem;
}

#carouselfeatured ol li:nth-child(1) {
    margin-top: 0px !important;
}

#carouselfeatured .carousel-caption {

    position: absolute;
    right: 0%;
    bottom: 0px;
    left: 0%;
}

#carouselfeatured h3 {
    font-weight: 800;

}

#carouselfeatured p {

    line-height: normal;
}

#carouselfeatured .stretched-link {
    color: #ffffff !important;
    text-decoration: none !important;
}

.stretched-link {
    cursor: pointer;
}


// * {

// font-family: "roboto", sans-serif;
// font-weight: 200;
// }

//     h1 {

//         font-family: "merriweather", sans-serif !important;
//         font-weight: 900;
//         font-size: 4rem !important;

// }
//     h2 {
//         font-family: "merriweather", sans-serif !important;
//         background-color: #ffffff !important;
//         padding: 0.5rem;
//         border-radius: 8px !important;
//         font-size: 1.9rem;
//     }
//     a {
//         color: #00bdb3 !important;
// }

.frn h1 {
    font-size: 3rem;
}

@media (max-width: 768px) {
    .frn h1 {
        font-size: 2rem;
    }
}

.frn_btn {
    background-color: transparent;
    border: #ffffff 1px solid;
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 900;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 280px;

    &.active {
        background-color: #ffffff;
        color: #0F443A;
    }
}

.frn_btn:hover {
    background-color: #ffffff;
    color: #0F443A;
    text-decoration: none;
}

.white_btn {
    background-color: transparent;
    border: #ffffff 1px solid;
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 900;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 280px;
}

.white_btn:hover {
    background-color: #ffffff;
    color: #d32329;
    text-decoration: none;
}


.fwbox {

    padding-top: 10px;
    background-color: #f8f9fa !important;

}

.col:nth-child(2) {
    position: relative;
    left: -1.9rem;
}


.ais-Panel {
    background-color: #ffffff !important;
    padding: 1.5rem;
    border-radius: 8px !important;
    margin-bottom: 1rem !important;

}

.container.intro {
    max-width: 65rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

.container.agro {
    max-width: 80rem;
    padding-left: 5rem;
    padding-right: 5rem;
}

@media (max-width: 768px) {
    .container.intro {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .container.agro {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.badge {
    background-color: #dee2e6;
    color: #212529;
}

// a.card-link {
//     color: #0e0e0e !important;
//     font-family: "merriweather", sans-serif !important;
// }

a.card-link:hover {
    color: #00bdb3 !important;
    text-decoration: none !important;
}

.card-body {
    padding-top: 0px !important;
}

.resreskin {
    div .row .col:nth-child(2) {
        padding-right: 0px !important;
        padding-left: 30px !important;
    }
}

.col-md-4.pr-4.mr-4 {
    position: relative;
    left: -15px;
    top: -1rem;
}

ol li:nth-child(1) {
    margin-top: 3rem !important;
}

li {
    padding: 0px !important;
}

.rescard {
    padding: 0px !important;
    margin-left: 15px;
    margin-bottom: 15px;
    border: none !important;

    &:hover {
        border: none !important;
    }

    a {
        font-size: 1.2rem !important;
        font-weight: bold;
    }

    h3 {
        margin-bottom: 0px !important;
    }

    hr {
        margin-bottom: 0.5rem !important;
    }
}

.resource-results-item {
    color: #3d444b !important;
}

.ais-RefinementList-showMore {
    background-color: #00bdb3 !important;

    &:hover {
        background-color: #0a8d86 !important;
    }
}

.divideralt {
    height: 0.3rem;
    width: 100%;
    background-color: #80d3cf;
    margin-bottom: 3rem;
    margin-left: 0.5rem;
    position: relative;
    top: -1rem;
    border-radius: 8px;
}

.ais-Panel-header {
    border: none !important;
    text-transform: none !important;
    font-size: 1rem !important;
    font-weight: bold !important;
}

.ais-RefinementList-labelText, .ais-Highlight {
    font-size: 0.9rem !important;
    color: #3d444b;
}

.ais-RefinementList-count {
    color: #585d70 !important;
    background-color: #f1f1f1 !important;
}

.ais-SearchBox-input {
    border: 2px solid #dde6ee !important;
    border-left: none !important;
    border-top: none !important;
    background-color: #f8f9fa !important;
}

hr {
    border: none !important;
}
